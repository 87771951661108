<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Headers"
                >
                    <template v-slot:description>
                        <div>
                            import { HbHeader, HbPageHeader } from 'hummingbird-aviary';
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>
        
        <hb-card title="Basic Full Width Header + Standard Page Header Usage + Code" class="mt-4 mb-6">
            <div class="mb-4 mx-6">
                <hb-header>
                    <HbPageHeader
                        title="Page Title Here"
                        description="Page description here."
                    />
                </hb-header>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-header>
    &lt;HbPageHeader
        title="Page Title Here"
        description="Page description here"
    />
&lt;/hb-header>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Basic Full Width Header + Page Header Alternate Slot Usage + Code" class="mt-4 mb-6">
            <div class="mb-4 mx-6">
                <hb-header>
                    <hb-page-header>
                        <template v-slot:title>
                            v-slot:title
                        </template>
                        <template v-slot:description>
                            v-slot:description
                        </template>
                    </hb-page-header>
                </hb-header>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-header>
    &lt;hb-page-header>
        &lt;template v-slot:title>
            v-slot:title
        &lt;/template>
        &lt;template v-slot:description>
            v-slot:description
        &lt;/template>
    &lt;/hb-page-header>
&lt;/hb-header>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Basic Two Column Header Slot Usage + Code" class="mt-4 mb-6">
            <div class="mb-4 mx-6">
                <hb-header>
                    <template v-slot:left>
                        v-slot:left
                    </template>
                    <template v-slot:right>
                        v-slot:right
                    </template>
                </hb-header>
            </div>
            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-header>
    &lt;template v-slot:left>
        v-slot:left
    &lt;/template>
    &lt;template v-slot:right>
        v-slot:right
    &lt;/template>
&lt;/hb-header>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="<hb-header> Props" class="my-6 pb-1">
            <hb-data-table
                :headers="hbHeaderPropHeaders"
                :items="hbHeaderPropItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="<hb-header> Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="hbHeaderSlotHeaders"
                :items="hbHeaderSlotItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="<hb-page-header> Props" class="my-6 pb-1">
            <hb-data-table
                :headers="hbPageHeaderPropHeaders"
                :items="hbPageHeaderPropItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="<hb-page-header> Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="hbPageHeaderSlotHeaders"
                :items="hbPageHeaderSlotItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="More Examples of Common Usage" class="my-6 pb-3">

            <div class="mt-6 mb-0 mx-6 pt-3 pb-0 px-0 hb-cloud-lighter">
                <hb-header>
                    <template v-slot:left>
                        <hb-page-header
                            title="Access Control"
                            description="Edit your access control integration here."
                        >
                        </hb-page-header>
                    </template>
                    <template v-slot:right>
                        <HbTextField
                            search
                            placeholder="Search"
                            :width="$vuetify.breakpoint.lgAndUp ? '370px' : '326px'"
                        />
                        <hb-btn class="ml-2" icon tooltip="Set Columns">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>

            <div class="pa-0 mx-6 my-9 pt-3 hb-cloud-lighter">
                <hb-breadcrumb @click="handleEmittedClickEvent('@click')">
                    Back to Wherever You Were
                </hb-breadcrumb>
                <hb-header>
                    <template v-slot:left>
                        <hb-page-header
                            title="Access Control"
                            description="Edit your access control integration here."
                        >
                        </hb-page-header>
                    </template>
                    <template v-slot:right>
                        <HbTextField
                            search
                            placeholder="Search"
                            :width="$vuetify.breakpoint.lgAndUp ? '370px' : '326px'"
                        />
                        <hb-btn class="ml-2" icon tooltip="Set Columns">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>

            <div class="pa-0 mx-6 my-9 pt-3 hb-cloud-lighter">
                <hb-notification
                    v-if="notification"
                    type="quick-actions"
                    v-model="notification"
                >
                    <template v-slot:condenseActions>
                        <hb-menu
                            options
                            options-hover-type="quick-actions"
                        >
                            <v-list>
                                <v-list-item :ripple="false">
                                    <v-list-item-title>Move-Out</v-list-item-title>
                                    <v-list-item-title>Move-In</v-list-item-title>
                                    <v-list-item-title>Take a Payment</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="primary">Move-Out</hb-btn>
                        <hb-btn small color="primary" class="ml-2">Move-In</hb-btn>
                        <hb-btn small color="primary" class="ml-2">Take a Payment</hb-btn>
                    </template>
                </hb-notification>
                <hb-header>
                    <template v-slot:left>
                        <hb-page-header
                            title="Access Control"
                            description="Edit your access control integration here."
                        >
                        </hb-page-header>
                    </template>
                    <template v-slot:right>
                        <HbTextField
                            search
                            placeholder="Search"
                            :width="$vuetify.breakpoint.lgAndUp ? '370px' : '326px'"
                        />
                        <hb-btn class="ml-2" icon tooltip="Set Columns">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>

            <div class="pa-0 mx-6 my-9 pt-3 hb-cloud-lighter">
                <hb-header full>
                    <hb-page-header
                        title="Spaces"
                        description="Manage your space information from each property here."
                    >
                    </hb-page-header>
                </hb-header>
                <hb-header>
                    <template v-slot:left>
                        <HbAutocomplete
                            v-model="spaces"
                            :items="['Vacancies', 'Leased']"
                            item-text="name"
                            rounded
                            placeholder="Select"
                            :clearable="false"
                            :width="$vuetify.breakpoint.lgAndUp ? '370px' : '326px'"
                            class="mt-1"
                        />
                    </template>
                    <template v-slot:right>
                        <HbTextField
                            search
                            placeholder="Search"
                            :width="$vuetify.breakpoint.lgAndUp ? '370px' : '326px'"
                            class="mt-1"
                        />
                        <hb-btn class="mt-1 ml-2" icon tooltip="Set Columns">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>

            <div class="pa-0 mx-6 my-9 pt-3 hb-cloud-lighter">
                <hb-header>
                    <template v-slot:left>
                        <HbHeaderInfo
                            type="contact"
                            title="John Smith"
                            is-military
                            status="gate lockout"
                            phone="+1 (310) 234-2354"
                            phone-is-verified
                            email="johnsmith@somewebsite.coms"
                            email-is-verified
                            address="100 Main Street, Los Angeles 90039"
                            multiple-access-codes
                            @emailClicked="handleEmittedClickEvent('@emailClicked')"
                            @pin="handleEmittedClickEvent('@pin')"
                        />
                    </template>
                    <template v-slot:right>
                        <HbTextField
                            search
                            placeholder="Search"
                            :width="$vuetify.breakpoint.lgAndUp ? '370px' : '326px'"
                        />
                        <hb-btn class="ml-2" icon tooltip="Set Columns">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>

            <div class="pa-0 mx-6 my-9 pt-3 hb-cloud-lighter">
                <hb-breadcrumb class="pb-1" @click="handleEmittedClickEvent('@click')">
                    Back to Wherever You Were
                </hb-breadcrumb>
                <hb-header>
                    <template v-slot:left>
                        <HbHeaderInfo
                            type="contact"
                            title="John Smith"
                            is-military
                            status="gate lockout"
                            phone="+1 (310) 234-2354"
                            phone-is-verified
                            email="johnsmith@somewebsite.coms"
                            email-is-verified
                            address="100 Main Street, Los Angeles 90039"
                            multiple-access-codes
                            @emailClicked="handleEmittedClickEvent('@emailClicked')"
                            @pin="handleEmittedClickEvent('@pin')"
                        />
                    </template>
                    <template v-slot:right>
                        <HbTextField
                            search
                            placeholder="Search"
                            :width="$vuetify.breakpoint.lgAndUp ? '370px' : '326px'"
                        />
                        <hb-btn class="ml-2" icon tooltip="Set Columns">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>

            <div class="pa-0 mx-6 my-9 pt-3 hb-cloud-lighter">
                <hb-header full :divider="false">
                    <HbHeaderInfo
                        type="contact"
                        title="John Smith"
                        is-military
                        status="gate lockout"
                        phone="+1 (310) 234-2354"
                        phone-is-verified
                        email="johnsmith@somewebsite.coms"
                        email-is-verified
                        address="100 Main Street, Los Angeles 90039"
                        multiple-access-codes
                        @emailClicked="handleEmittedClickEvent('@emailClicked')"
                        @pin="handleEmittedClickEvent('@pin')"
                    />
                </hb-header>
                <hb-header one-action :padding="false" align-top>
                    <template v-slot:left>
                        <hb-tabs v-model="activeTab1">
                            <v-tab
                                v-for="(tab, i) in tabs"
                                :key="'tab' + i"
                                :ripple="false"
                            >
                                Space {{ tab.number }}
                            </v-tab>
                        </hb-tabs>
                    </template>
                    <template v-slot:right>
                        <hb-btn class="mr-1" :class="{'mr-3' : $vuetify.breakpoint.xs}" icon tooltip="Sidebar">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>

            <div class="pa-0 mx-6 mt-9 mb-3 pt-3 hb-cloud-lighter">
                <hb-breadcrumb class="pb-1" @click="handleEmittedClickEvent('@click')">
                    Back to Wherever You Were
                </hb-breadcrumb>
                <hb-header full :divider="false">
                    <HbHeaderInfo
                        type="contact"
                        title="John Smith"
                        is-military
                        status="gate lockout"
                        phone="+1 (310) 234-2354"
                        phone-is-verified
                        email="johnsmith@somewebsite.coms"
                        email-is-verified
                        address="100 Main Street, Los Angeles 90039"
                        multiple-access-codes
                        @emailClicked="handleEmittedClickEvent('@emailClicked')"
                        @pin="handleEmittedClickEvent('@pin')"
                    />
                </hb-header>
                <hb-header one-action :padding="false" align-top>
                    <template v-slot:left>
                        <hb-tabs v-model="activeTab2">
                            <v-tab
                                v-for="(tab, i) in tabs"
                                :key="'tab' + i"
                                :ripple="false"
                            >
                                Space {{ tab.number }}
                            </v-tab>
                        </hb-tabs>
                    </template>
                    <template v-slot:right>
                        <hb-btn class="mr-1" :class="{'mr-3' : $vuetify.breakpoint.xs}" icon tooltip="Sidebar">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemHeaders",
        data: function() {
            return {
                contact: { "id": "123213213", "salutation": "", "first": "Bellatrix", "middle": "", "last": "Lestrange", "suffix": "", "email": "bellatrix@lestrange123.com", "company": "", "dob": null, "ssn": null, "gender": null, "status": "Current", "driver_license": null, "active_military": 1, "military_branch": null, "source": null, "Phones": [ { "id": "1VBqVd0vwy", "type": "Cell", "phone": "12225030275", "sms": true } ], "Addresses": [ { "id": "123123213421", "type": "Home", "Address": { "address": "123121 Main Street", "address2": "A", "city": "El Paso", "state": "CA", "neighborhood": null, "zip": "92604", "country": null, "lat": null, "lng": null } } ], "Relationships": [] },
                notification: true,
                success: false,
                description: '',
                activeTab1: '',
                activeTab2: '',
                condenseNotificationActions: false,
                tabs: [
                    { number: '200' },
                    { number: '233' },
                    { number: '262' },
                    { number: '263' },
                    { number: '264' }
                ],
                spaces: 'Vacancies',
                hbHeaderPropHeaders: [
                    { text: 'Name', value: 'name', width: '125' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '100' },
                    { text: 'Description', value: 'description' },
                ],
                hbHeaderPropItems: [
                    { name: 'align-top', type: 'boolean', default: 'false', description: 'Vertical alignment helper to align left and right slot columns to the top' },
                    { name: 'align-center', type: 'boolean', default: 'true', description: 'Vertical alignment helper to align left and right slot columns to the center.' },
                    { name: 'align-bottom', type: 'boolean', default: 'false', description: 'Vertical alignment helper to align left and right slot columns to the bottom.' },
                    { name: 'padding', type: 'boolean', default: 'true', description: 'Set to false to turn off default padding.' },
                    { name: 'divider', type: 'boolean', default: 'true', description: 'Set to false to turn off bottom divider.' },
                    { name: 'one-action', type: 'boolean', default: 'false', description: 'If changed to true, this will set the left slot content to 11/12th of the total container width and the right slot to 1/12th of the total container width. Best for when the right side will only have one action icon or button.' },
                    { name: 'fewer-actions', type: 'boolean', default: 'false', description: 'If changed to true, this will set the left slot content to 9/12th of the total container width and the right slot to 3/12th of the total container width. Useful for when the right side will have more than one action icon or button.' },
                    { name: 'full', type: 'boolean', default: 'false', description: 'If changed to true, this will disable/ignore left and right slot functionality and content will be set to full container width. If you are just the using default content slot, this prop is not needed as the default width is already full width.' },
                ],
                hbHeaderSlotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                hbHeaderSlotItems: [
                    { name: 'left', description: 'Left side content slot. Default width is 50% of the total width of the container. This slot is disabled if the "full" prop is set to true.' },
                    { name: 'right', description: 'Right side content slot. Default width is 50% of the total width of the container. This slot is disabled if the "full" prop is set to true.' },
                ],
                hbPageHeaderPropHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default' },
                    { text: 'Description', value: 'description' },
                ],
                hbPageHeaderPropItems: [
                    { name: 'title', type: 'string', default: 'undefined', description: 'Sets the page title text with correct visual styling.' },
                    { name: 'description', type: 'string', default: 'undefined', description: 'Sets the page description text with correct visual styling.' },
                ],
                hbPageHeaderSlotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                hbPageHeaderSlotItems: [
                    { name: 'title', description: 'Alternate usage for placing title content as opposed to using the standard title prop.' },
                    { name: 'description', description: 'Alternate usage for placing description content as opposed to using the standard description prop.' },
                ],
            };
        },
        methods: {
            handleEmittedClickEvent(e){
                this.success = true;
                this.description = "This " + e + " action is emitted from it's base level custom component.";
            }
        }
    }
</script>

<style scoped>

</style>
